// import moment from 'moment'
import moment from '@/libs/moment'

export const flatPickerConfig = {
  data() {
    return {
      timezone: localStorage.getItem('timezone'),
      flatPickerDateTimeConfig: {
        disable: [
          function disableDates(date) {
            return date <= Date.now() - 1 * 24 * 60 * 60 * 1000
          },
        ],
        enableTime: true,
        altInput: true,
        dateFormat: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
        altFormat: 'MM/DD/YYYY h:mm a',
        allowInput: true,
        // parseDate: (datestr, format) => moment.utc(datestr).format(format),
        parseDate: (datestr, format) => moment.tz(datestr, format, this.timezone).format(format),
        formatDate: (date, format) => {
          if (format === this.flatPickerDateTimeConfig.altFormat) {
            return moment(date).format(format)
            // return moment.tz(date, this.timezone).format(format)
          }
          return moment.utc(date).format(format)
        },
      },
    }
  },
}

export const _ = null
