import axios from '@axios'

export const sendCommunication = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/communications/send', params)
  return res
}

export const fetchScheduledEmailsList = async (params: any): Promise<any> => {
  const res = await axios.get('/auth/communications/delay/get', {
    params,
  })
  return res
}

export const cancelScheduledEmail = async (id: number | string): Promise<any> => {
  const res = await axios.delete(`/auth/communications/delay/${id}/delete`)
  return res
}

export const editScheduledEmail = async (params: any): Promise<any> => {
  const res = await axios.post(`/auth/communications/delay/${params.id}`, params)
  return res
}

export const fetchCommunicationTypes = async (): Promise<any> => {
  const res = await axios.get('/auth/communication-types')
  return res
}
