import axios from '@axios'

export const fetchLettersList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/letter-templates', { params })
  return res
}

export const fetchLetterTemplate = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/letter-templates/${id}`)
  return res
}

export const createLetterTemplate = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/letter-templates', params)
  return res
}

export const updateLetterTemplate = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/letter-templates/${params.id}`, params)
  return res
}

export const deleteLetterTemplate = async (id: string): Promise<any> => {
  const res = await axios.delete(`/auth/letter-templates/${id}`)
  return res
}

export const cloneLetterTemplate = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/letter-templates/${id}/clone`)
  return res
}
