import axios from '@axios'

export const fetchSmsTemplatesList = async (params?: any): Promise<any> => {
  const res = await axios.get('/auth/sms-templates', { params })
  return res
}

export const fetchSmsTemplate = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/sms-templates/${id}`)
  return res
}

export const createSmsTemplate = async (params: any): Promise<any> => {
  const res = await axios.post('/auth/sms-templates', params)
  return res
}

export const updateSmsTemplate = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/sms-templates/${params.id}`, params)
  return res
}

export const deleteSmsTemplate = async (id: string): Promise<any> => {
  const res = await axios.delete(`auth/sms-templates/${id}`)
  return res
}

export const cloneSmsTemplate = async (id: string): Promise<any> => {
  const res = await axios.get(`auth/sms-templates/${id}/clone`)
  return res
}
